import type { LinkProps } from "@remix-run/react";
import { Link as RemixLinkComponent } from "@remix-run/react";
import { forwardRef } from "react";

// This component is used to generate the correct link component depending on the context (Storybook or Remix Router)
export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  try {
    return (
      <RemixLinkComponent
        // to={props.to}
        // prefetch={process.env.NODE_ENV === "production" ? "intent" : "none"}
        ref={ref}
        {...props}
      >
        {props.children}
      </RemixLinkComponent>
    );
  } catch (error) {
    console.warn({
      message: "Remix Link component switched to <a></a> (Not in Remix Router Context) : ",
      error,
      props,
    });
    return (
      <a {...props} href={(props.to as string) || ""}>
        {props.children}
      </a>
    );
  }
});
